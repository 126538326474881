import styled from 'styled-components';

export const Wrapper = styled.main`
  margin-left:var(--coupon-left-margin);

  .flex-div {
    display: flex;
    flex-direction: var(--flex-direction); 
  }

  .color-plate {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: var(--always-black);
  }

  .color-plate H3 {
    font-size: 20px;
    margin: 10px 10px 5px 10px;
    color: var(--always-black);
  }

  @media screen and (min-width: 950px) {
    .left-flex {
      min-width: 402px;
    }

    .right-flex {
      width: 100%;
    }
  }

  @media screen and (max-width: 950px) {
    margin-left: 0px;

    .flex-div {
      flex-direction: column;
      margin-top: var(--mobile-margin-top);
    }

    .right-flex {
      display: none;
    }

    .beige-div {
      height: 112px;
    }
  }

  @media screen and (min-width: 950px) {
    .beige-div {
      height: 170px;
    }
  }

  .beige-div {
    background: var(--background_4);
    display: flex;
    padding: 0 0 0 10px;
  }

  .stats, .explain {
    display: flex;
    flex-direction: column;
  }

  .stats {
    flex: 2;
    padding: 9px 0 5px 0;
    color: var(--always-black);
  }

  .explain {
    flex: 1;
    border-left: 1px solid #060707;
  }

  .icon-flex {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    .icon-flex {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .icon-flex span {
      font-size: 14px;
      margin-top: 4px;
    }

    .stats .explain {
      justify-content: flex-start;
    }

  }

  @media screen and (min-width: 601px) {
    .icon-flex span {
      font-size: 11px;
      margin-left: 10px;
    }

    .explain {
      padding-left: 12px;
      margin: 12px 0 12px 22px;
    }

    .stats .explain {
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 950px) {
    #start-date-wrapper {
      border-bottom: 1px solid var(--always-black);
    }
  }

  @media screen and (min-width: 950px) {
    .underline {
      border-bottom: 1px solid var(--off-black);
    }
  }

  .smaller-font {
    font-size: 18px;
  }

  .small-span {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .pink-div {
    background-color: var(--background_4);
    padding: 22px 24px 21px 24px;
  }

  .caption {
    font-weight: bold;
    font-size: 12px;
  }

  .sum-ul {
    list-style: none;
    font-size: 11px;
    padding: 0;
    margin-bottom: 19px;
  }

  .sum-ul li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
  }

  .m-span {
    margin-left: 7px;
  }

  .date-flex {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .cursor {
    cursor: pointer;
  }

  .bold {
    font-weight: bold;
  }

  .close-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px;
    margin-bottom: 26px;
    cursor: pointer;
  }

  .input-flex {
    display: flex;
    justify-content: space-between;
    width: 225px;
  }
 

  .fixture {
    height: 70px;
    width: 100%;
  }

  .text-div {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    color: var(--text-black);
    padding-top: 7px;
    padding-left: 11px;
  }

  .top-div {
    display: flex;
    font-weight: bold;
    margin-bottom: 3px;
  }

  .small-font {
    font-size: 8px;
    margin-bottom: 8px;
  }

  .m-right {
    margin-right: 5px;
  }

  .bg {
    background: var(--background_4);
  }

  .pixels-top {
    margin: 0 10px 3px 0;
  }

  @media screen and (max-width: 950px) {
    .pixels-top {
      display: none !important;
    }
  }

  .fild-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-start;
    padding: 10px 14px 0 0;

  }

  .signs-div {
    display: flex;
    align-items: center;
  }

  .black-div {
    background: var(--off-black);
    color: var(--text-white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 8px 4px 9px;
    font-size: 13px;
    line-height: 13px;
    margin-top: 18px;
    height: 17px;
  }

  @media screen and (max-width: 950px) {
    .black-div {
      min-width: 22px;
    }
  }

  @media screen and (min-width: 950px) {
    .black-div {
      min-width: 27px;
    }
  }

  .percentage-span {
    font-size: 8px;
    line-height: 9px;
    color: var(--text-black);
  }

  .margin-left {
    margin-bottom: 6px;
    margin-left: 6px;
    text-align: center;
  }
`;

export const NoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px 0;
`