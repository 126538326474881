import React, { useState } from 'react';
import { Wrapper } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { IkonIcon, Button } from '../../reusable-components';
import { operatorAdviceErrorOccurred } from "../../App";

function renderSwitch({
  shouldRenderConfirm,
  toggle,
  syndicate,
  phrases,
  handleClick,
  errorResponse,
  buttonEnabled
}) {

  var enabled = buttonEnabled;

  const onConfirmClick = (syndicate) => {
    if (enabled) {
      handleClick(syndicate);
      enabled = false;
    }
  }

  if (shouldRenderConfirm) {

    if (errorResponse !== undefined) {
      return (
        <>
          <span>{errorResponse}</span>
          <Button width={350} onClick={() => toggle(false)}>Ok</Button>
        </>
      );
    }

    return (
      <>
        <span>{phrases.paymentDone}</span>
        <Button width={350} onClick={() => toggle(false)}>Ok</Button>
      </>
    );
  }

  return (
    <>
      <span>{phrases.confirmPurchase} {syndicate.totalAmountPerShare.amount} {syndicate.totalAmountPerShare.currency}?</span>
      <Button enabled={enabled} type="submit" width={350} onClick={() => onConfirmClick(syndicate)}>{phrases.pay}</Button>
    </>
  );
}

const SyndicateConfirm = ({
  syndicate,
  toggle

}) => {
  const [shouldRenderConfirm, setShouldRenderConfirm] = useState(false);
  const [errorResponse, setErrorResponse] = useState(undefined);
  const [buttonEnabled, setbuttonEnabled] = useState(true);

  const { t } = useTranslation();
  const phrases = {
    confirmPurchase: t('do_you_want_to_place_bet'),
    pay: t('pay'),
    paymentDone: t('your_bet_is_paid'),
    insufficientFunds: t('insufficient_funds'),
    gamingLimitsReached: t('gaming_limits_reached'),
    unableToCompletePurchase: t('unable_to_complete_purchase'),
  };

  const handleClose = () => {
    toggle(false);
  }

  const handleClick = (syndicate) => {
    setbuttonEnabled(false);
    syndicate.purchase().then(resp => {
      return resp.json();
    }).then(j => {

      if (j.errorCode !== undefined) {
        let errorText = phrases.unableToCompletePurchase;

        if (j.localizedOperatorMessage !== undefined && j.localizedOperatorMessage !== '') {
          errorText = j.localizedOperatorMessage
        }

        switch (j.errorCode) {
          case 101:
            errorText = phrases.unableToCompletePurchase; // TODO replace with 'session expired'
            break;
          case 102:
            errorText = phrases.insufficientFunds;
            break;
          case 105:
            errorText = phrases.gamingLimitsReached;
            break;
          default:
            break;
        }

        operatorAdviceErrorOccurred({
          errorCode: j.errorCode,
          errorMessage: errorText,
          operatorErrorCode: j.operatorErrorCode,
        })
        setErrorResponse(errorText);
      }
      setShouldRenderConfirm(true);
      setbuttonEnabled(true);
    });
  }

  return (
    <Wrapper>
      <div className="onclick-div" onClick={handleClose} />
      <div className="confirm-window">
        <div className="flex-end">
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>
        {IkonIcon(32, 30, "var(--text-black)")}

        {renderSwitch({
          shouldRenderConfirm,
          toggle,
          syndicate,
          phrases,
          handleClick,
          errorResponse,
          buttonEnabled
        })}
      </div>
    </Wrapper>
  );
}

export default SyndicateConfirm;