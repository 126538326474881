import { useState } from 'react';
import { useParams } from 'react-router-dom';
import NavBar from '../navbar';
import WinInfo from './components/wininfo';
import CouponInfo from './components/couponinfo';
import { CouponViewWrapper } from './styles';
import SelectedFixture from '../routedround/components/selectedfixture/selectedfixture';
import useCouponShare from '../couponShares/useCouponShare';
import useInterval from 'react-useinterval';
import useDetailRound from '../rounds/useDetailRound';
import Progress from '../common/progress';
import PinkWin from './components/pinkwin';
import { isFixtureAfterGameStart } from '../common/DateTimeHelper';
import { useMediaQuery } from "react-responsive/src";
import useDetailedFixture from "../fixtures/useDetailedFixture";
import ActiveCouponHeader from './components/activecouponheader/activecouponheader';
import { themeC, IsRightToLeftTheme } from '../theme/themehelper';
import SlimCoupon from '../slimcoupon/slimcoupon';

const ContentArea = ({ fixture, theme }) => {
  const { fixtureDetails } = useDetailedFixture(fixture.id);

  if (fixture) {
    const isAfterGameStart = isFixtureAfterGameStart(fixture);
    return <SelectedFixture fixtureDetail={fixtureDetails} isAfterGameStart={isAfterGameStart} roundFixture={fixture} theme={theme} />;
  }
  return <Progress />;
}

function calcCorrectSigns(row, correctRow) {
  let numCorrect = 0;
  for (let i = 0; i < correctRow.signs.length; i++) {
    if (row.signs[i] === correctRow.signs[i]) {
      numCorrect++;
    }
  }

  return numCorrect;
}

export default function CouponView() {

  let isRightToLeft = IsRightToLeftTheme(window.theme);

  const { couponId } = useParams();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 950px)' });
  const initialFixtureIndexSelected = isSmallScreen ? -1 : 0;
  const [fixtureValue, setFixtureValue] = useState(initialFixtureIndexSelected);
  const couponShareResponse = useCouponShare(couponId);

  const detailRoundResponse = useDetailRound(couponShareResponse[0] === undefined ? undefined : couponShareResponse[0].coupon.roundId);
  const round = detailRoundResponse.round;
  let couponShare = couponShareResponse[0];

  useInterval(() => {
    couponShareResponse[1].reloadCouponShare();
    detailRoundResponse.reloadDetailRound();
  }, 20000);

  if (couponShareResponse[0] === undefined) {
    return null;
  }

  if (detailRoundResponse.status === "loading") {
    return <div></div>;
  }

  const fixture = fixtureValue === undefined || !round ? undefined : round.fixtures[fixtureValue];

  const isAfterDeadline = new Date(round.openTo) < Date.now();

  const deepCoupon = couponShare.coupon.coupon;
  const detailedBets = deepCoupon.bets;
  const correctRow = couponShare.coupon.correctRow;
  const preliminaryOutcome = {};

  let maxCorrectSigns = 0;

  if (deepCoupon && detailedBets && correctRow) {
    for (const betKey in detailedBets) {
      const betRows = detailedBets[betKey].rows;
      for (const rowKey in betRows) {
        const row = betRows[rowKey];
        const correctSigns = calcCorrectSigns(row, correctRow);

        if (correctSigns > maxCorrectSigns) {
          maxCorrectSigns = correctSigns;
        }

        if (!preliminaryOutcome[correctSigns]) {
          preliminaryOutcome[correctSigns] = 1
        } else {
          preliminaryOutcome[correctSigns] = preliminaryOutcome[correctSigns] + 1;
        }
      }
    }
  }

  let betResult = { amount: '0', currency: round.operatorTurnover.currency };

  if (couponShare.operatorPreliminaryResult) {
    betResult = couponShare.operatorPreliminaryResult;
  }

  if (couponShare.operatorResult) {
    betResult = couponShare.operatorResult;
  }

  let theme = window.theme;
  let isGold = (theme === themeC.Gold);

  if (isSmallScreen) {
    return (
      <>
        <NavBar whichToUnderline="3" />
        <CouponViewWrapper>
          <div className="left-flex">

            {isGold ?
              <ActiveCouponHeader round={round} winAmount={betResult} nCorrectRows={maxCorrectSigns} couponShareId={couponShare.id} /> :
              <PinkWin winAmount={betResult} nCorrectRows={maxCorrectSigns} couponShareId={couponShare.id} isRightToLeft={isRightToLeft} />
            }

            <div className="top-border"></div>

            <SlimCoupon
              round={round}
              theme={theme}
              coupon={couponShare.coupon.coupon}
              onChangeFixture={setFixtureValue}
              couponFixtureSummaries={couponShare.coupon.fixtures}
            />

            {(isAfterDeadline && <WinInfo round={round} couponShare={couponShare} isRightToLeft={isRightToLeft} />)}

            <CouponInfo round={round} couponShare={couponShare} isRightToLeft={isRightToLeft} />

          </div>
        </CouponViewWrapper>
      </>
    );
  }



  return (
    <>
      <NavBar whichToUnderline="3" />
      <CouponViewWrapper>
        <div className="left-flex">

          {isGold ?
            <ActiveCouponHeader round={round} winAmount={betResult} nCorrectRows={maxCorrectSigns} couponShareId={couponShare.id} /> :
            <PinkWin winAmount={betResult} nCorrectRows={maxCorrectSigns} couponShareId={couponShare.id} isRightToLeft={isRightToLeft} />
          }

          <div className="top-border"></div>

          <SlimCoupon
            round={round}
            theme={theme}
            coupon={couponShare.coupon.coupon}
            onChangeFixture={setFixtureValue}
            couponFixtureSummaries={couponShare.coupon.fixtures}
          />

          {(isAfterDeadline && <WinInfo round={round} couponShare={couponShare} isRightToLeft={isRightToLeft} />)}

          <CouponInfo round={round} couponShare={couponShare} isRightToLeft={isRightToLeft} />

        </div>
        <div className="right-flex">
          <ContentArea fixture={fixture} theme={theme} />
        </div>
      </CouponViewWrapper>
    </>
  );
}