import { useState } from 'react';
import { Wrapper } from './selectedfixture_styles';
import { useTranslation } from "react-i18next";
import Progress from '../../../common/progress';
import MatchSummary from './components/matchsummary';
import { IsRightToLeftTheme } from '../../../theme/themehelper';
import MatchInformation from '../../../slimcoupon/matchinformation';
import { Grid, SxProps, Theme } from '@mui/material';
import { FixtureDetail } from '../../../models/fixturedetail';
import { Fixture } from '../../../models/fixture';
import MatchStatistics from '../../../slimcoupon/matchstatistics';
import LeagueTable from '../../../slimcoupon/leaguetable';
import ScoreBoard from '../scoreboard';
import { Round } from '../../../models/round';

function SelectedFixture({ fixtureDetail, isAfterGameStart, roundFixture, theme, round}:
  { fixtureDetail: FixtureDetail, isAfterGameStart: boolean, roundFixture: Fixture, theme: string, round:Round }) {

  const [menuIndex, setMenuIndex] = useState(isAfterGameStart ? 3 : 0);
  const { t } = useTranslation();

  let isRightToLeft = IsRightToLeftTheme(theme);

  if (!roundFixture) {
    return (
      <Wrapper>
        <div className="green-div" style={{ height: '100%' }}>
          <Progress />
        </div>
      </Wrapper>
    );
  }

  let ComponentToRender;

  const componentGrid: SxProps<Theme> = {
    overflowY: 'auto',
    backgroundColor: 'var(--fixtureinformation-background)'
  }

  switch (menuIndex) {
    case 0:
      ComponentToRender = MatchInformation;
      break;
    case 1:
      ComponentToRender = MatchStatistics;
      break;
    case 2:
      ComponentToRender = LeagueTable;
      break;
    case 3:
      ComponentToRender = MatchSummary;
      break;
    default:
      ComponentToRender = MatchInformation;
      break;
  }

  return (
    <Wrapper>
      <ScoreBoard fixtureDetail={fixtureDetail} roundFixture={roundFixture} theme={theme} round={round}/>
      <div>
        <div className={'menu'}>
          <div className={'menu-item'} onClick={() => setMenuIndex(0)}>
            <span className={menuIndex === 0 ? "underline-menu" : ""}>{t('game_info')}</span>
          </div>
          <div className={'menu-item'} onClick={() => setMenuIndex(1)}>
            <span className={menuIndex === 1 ? "underline-menu" : ""}>{t('statistics')}</span>
          </div>
          <div className={'menu-item'} onClick={() => setMenuIndex(2)}>
            <span className={menuIndex === 2 ? "underline-menu" : ""}>{t('table')}</span>
          </div>
          <div className={'menu-item'} onClick={() => setMenuIndex(3)}>
            <span className={menuIndex === 3 ? "underline-menu" : ""}>{t('game_summary')}</span>
          </div>
        </div>
      </div>
      {fixtureDetail &&
        <Grid container direction='column' alignItems='center' sx={componentGrid}>
          <ComponentToRender fixture={roundFixture} fixtureDetails={fixtureDetail} isRightToLeft={isRightToLeft} />
        </Grid>
      }
    </Wrapper>
  );
}

export default SelectedFixture;

