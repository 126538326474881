export const initTheme = (mode) => {

    if (mode === "blue") {
        import("./blue.css");
        return;
    }

    if (mode === "blue2") {
        import("./blue2.css");
        return;
    }

    if (mode === "gold") {
        import("./gold.css");
        return;
    }
    
    if (mode === "medina") {
        import("./medina.css");
        return;
    }

    if (mode === "medinaar") {
        import("./medinaar.css");
        return;
    }

    if (mode === "sultan") {
        import("./sultan.css");
        return;
    }

    if (mode === "jet") {
        import("./jet.css");
        return;
    }

    if (mode === "toto") {
        import("./toto.css");
        return;
    }

    if (mode === "totome") {
        import("./totome.css");
        return;
    }

    if (mode === "win90") {
        import("./win90.css");
        return;
    }

    if (mode === "win90me") {
        import("./win90me.css");
        return;
    }

    if (mode === "betconstruct") {
        import("./betconstruct.css");
        return;
    }

    if (mode === "betconstructme") {
        import("./betconstructme.css");
        return;
    }

    if (mode === "manoto") {
        import("./manoto.css");
        return;
    }

    if (mode === "manotome") {
        import("./manotome.css");
        return;
    }

    if (mode === "betfa") {
        import("./betfa.css");
        return;
    }

    if (mode === "betfame") {
        import("./betfame.css");
        return;
    }

    if (mode === "takbet") {
        import("./takbet.css");
        return;
    }

    if (mode === "takbetme") {
        import("./takbetme.css");
        return;
    }

    if (mode === "marcbet") {
        import("./marcbet.css");
        return;
    }

    if (mode === "marcbetme") {
        import("./marcbetme.css");
        return;
    }

    if (mode === "irbet365") {
        import("./irbet365.css");
        return;
    }

    if (mode === "irbet365me") {
        import("./irbet365me.css");
        return;
    }

    if (mode === "elit") {
        import("./elit.css");
        return;
    }

    if (mode === "metro") {
        import("./metro.css");
        return;
    }

    if (mode === "cwinz") {
        import("./cwinz.css");
        return;
    }

    if (mode === "kung") {
        import("./kung.css");
        return;
    }
    
    if (mode === "betbeto") {
        import("./betbeto.css");
        return;
    }

    if (mode === "dbet") {
        import("./dbet.css");
        return;
    }

    if (mode === "orange") {
        import("./orange.css");
        return;
    }

    if (mode === "wood") {
        import("./wood.css");
        return;
    }

    if (mode === "lion") {
        import("./lion.css");
        return;
    }

    if (mode === "video") {
        import("./video.css");
        return;
    }

    if (mode === "mrvegas") {
        import("./mrvegas.css");
        return;
    }

    import("./standard.css");
}