import { Grid, SxProps, ToggleButton, Typography, Theme } from '@mui/material';
import { DetailedRound } from '../models/round';
import { Syndicate } from '../models/syndicate';
import { IsLeo, IsRightToLeftTheme, themeC } from '../theme/themehelper';

const SyndicateCoupon = ({ syndicate, round, theme }: { syndicate: Syndicate, round: DetailedRound, theme: string }) => {

  if (round === undefined) {
    return (<></>);
  }

  let button: SxProps<Theme> = {
    padding: "0px 5px",
    textAlign: "center",
    height: "40px",
    fontFamily: "var(--font-body)",
    minWidth: "40px",
    maxWidth: "40px",
    marginRight: "10px",
    border: 'var(--sign-border-width) solid var(--off-black)',
    borderRadius: 'var(--sign-border-radius)',
    background: 'var(--sign-background-not-selected)',
    color: 'var(--sign-color)',
    "&.Mui-selected": {
      color: 'var(--sign-color-selected)',
      backgroundColor: 'var(--sign-background)'
    }
  }

  const lastButton: SxProps<Theme> = {
    marginRight: "0px",
  }

  let signText: SxProps<Theme> = {
    fontSize: "13px",
    lineHeight: "18px",
    fontFamily: "var(--font-body)"
  }

  let isRightToLeft = IsRightToLeftTheme(theme);

  let teams: SxProps<Theme> = {
    fontSize: "13px",
    lineHeight: "19px",
    fontWeight: "500",
    fontFamily: "var(--font-body)",
    textAlign: isRightToLeft ? "right" : ""
  }

  let gameNr: SxProps<Theme> = {
    width: "20px",
    marginRight: "5px"
  }

  const styleRow: SxProps<Theme> = {
    color: "var(--text-black)",
    padding: "10px",
    borderBottom: "1px solid var(--off-black)"
  };

  let isReduced = syndicate.pendingRoundCoupon.bets[0].system.type === "REDUCED";
  let isSingleRows = syndicate.pendingRoundCoupon.bets.length > 1;
  type ScoreObject = {
    "1": number;
    "X": number;
    "2": number;
  };
  let scores: ScoreObject[] = [];

  if (isSingleRows) {
    for (let game = 0; game < round.fixtures.length; game++) {
      scores.push({ "1": 0, "X": 0, "2": 0 })
    }

    for (let index = 0; index < syndicate.pendingRoundCoupon.bets.length; index++) {
      const bet = syndicate.pendingRoundCoupon.bets[index];
      for (let game = 0; game < bet.legs.length; game++) {
        let picks = bet.legs[game].signs;
        let score = scores[game];
        if (picks.indexOf("ONE") > -1) {
          score["1"]++;
        }
        if (picks.indexOf("X") > -1) {
          score["X"]++;
        }
        if (picks.indexOf("TWO") > -1) {
          score["2"]++;
        }

      }
    }
  }

  function isSelected(index: number, sign: string): boolean {
    if (syndicate.pendingRoundCoupon.bets.length > 1)
      return false;
    if (isReduced)
      return false;
    return syndicate.pendingRoundCoupon.bets[0].legs[index].signs.indexOf(sign) > -1
  }

  function getSignStyle(round: DetailedRound, index: number, sign: string): SxProps<Theme> {

    if (isReduced) {

      let summary = 0;
      let leg = syndicate.pendingRoundCoupon.bets[0].legs[index];
      let found = leg.signs.indexOf(sign) > -1;
      let percent = 1 / leg.signs.length;
      summary = found ? percent : 0;

      let partial: SxProps<Theme> = {
        backgroundImage: 'linear-gradient(to top, var(--sign-background) 0 ' + summary * 100 + '%, var(--sign-background-not-selected) ' + summary * 100 + '% 100%)',
        color: summary > 0.5 ? 'var(--sign-color-selected)' : 'var(--sign-color)'
      }
      return { ...button, ...partial } as SxProps<Theme>
    }

    if (isSingleRows) {

      let summary = 0;
      switch (sign) {
        case "ONE":
          summary = scores[index]["1"] / (scores[index]["1"] + scores[index]["X"] + scores[index]["2"])
          break;
        case "X":
          summary = scores[index]["X"] / (scores[index]["1"] + scores[index]["X"] + scores[index]["2"])
          break;
        case "TWO":
          summary = scores[index]["2"] / (scores[index]["1"] + scores[index]["X"] + scores[index]["2"])
          break;
      }
      let partial: SxProps<Theme> = {
        backgroundImage: 'linear-gradient(to top, var(--sign-background) 0 ' + summary * 100 + '%, var(--sign-background-not-selected) ' + summary * 100 + '% 100%)',
        color: summary > 0.5 ? 'var(--sign-color-selected)' : 'var(--sign-color)'
      }
      return { ...button, ...partial } as SxProps<Theme>
    }

    return button;
  }

  if (IsLeo(theme)) {
    signText.fontWeight = "bold";
  }

  if ((theme === themeC.Kung)) {
    signText = {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "23.44px",
      fontFamily: "var(--font-body)"
    }
    button = { ...button, ...{ border: '1px solid #249CEC' } };
  }

  if ((theme === themeC.Dbet)) {
    signText = {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "23.44px",
      fontFamily: "var(--font-body)"
    }

    teams = {
      fontSize: "14px",
      lineHeight: "16.41px",
      fontWeight: "700",
      fontFamily: "var(--font-body)",
      textAlign: isRightToLeft ? "right" : ""
    }
    gameNr.color = "#5E5E5E";
    button = { ...button, ...{ border: '1px solid #249CEC' } };
  };

  if ((theme === themeC.Video)) {
    signText = {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "23.44px",
      fontFamily: "var(--font-body)"
    }

    teams = {
      fontSize: "14px",
      lineHeight: "16.41px",
      fontWeight: "700",
      fontFamily: "var(--font-body)",
      textAlign: isRightToLeft ? "right" : ""
    }
    gameNr.color = "#5E5E5E";
    button = { ...button, ...{ border: '1px solid #249CEC' } };
  };

  if ((theme === themeC.MrVegas)) {
    signText = {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "23.44px",
      fontFamily: "var(--font-body)"
    }

    teams = {
      fontSize: "14px",
      lineHeight: "16.41px",
      fontWeight: "700",
      fontFamily: "var(--font-body)",
      textAlign: isRightToLeft ? "right" : ""
    }
    gameNr.color = "#5E5E5E";
    button = { ...button, ...{ border: '1px solid #249CEC' } };
  };

  return (
    <Grid container sx={{ justifyContent: "center", marginTop: "30px", marginBottom: "20px" }}>
      <Grid container direction="column"
        sx={{ minWidth: "340px", maxWidth: "500px", background: 'var(--background_2)', margin: "0px" }}>
        {round.fixtures.map((fixture, i) => {

          return (
            <Grid key={"row_header" + i} sx={styleRow}>
              <Grid container key={"row_" + i}
                sx={{ flexDirection: "var(--flex-direction)", flexWrap: "nowrap" }}
                alignItems="stretch">
                <Grid item>
                  <Typography
                    sx={{ ...teams, ...gameNr } as SxProps<Theme>}>{i + 1}</Typography>
                </Grid>
                <Grid item sx={{ flexGrow: 1, paddingRight: "5px" }}>
                  <Grid container sx={{ flexDirection: "var(--flex-direction)" }}>
                    <Typography sx={teams}>{fixture.homeTeam.name}</Typography>
                  </Grid>
                  <Grid container sx={{ flexDirection: "var(--flex-direction)" }}>
                    <Typography sx={teams}>{fixture.awayTeam.name}</Typography>
                  </Grid>

                </Grid>

                <Grid item sx={{ minHeight: '100%' }}>
                  <Grid container alignItems="center" sx={{
                    minHeight: '100%',
                    flexDirection: "var(--flex-direction)",
                    flexWrap: "nowrap"
                  }}>
                    <ToggleButton name="1"
                      selected={isSelected(i, "ONE")}
                      value="1" sx={getSignStyle(round, i, "ONE")}>
                      <Typography sx={signText}>1</Typography>
                    </ToggleButton>
                    <ToggleButton name="X"
                      selected={isSelected(i, "X")}
                      value="X" sx={getSignStyle(round, i, "X")}>
                      <Typography sx={signText}>X</Typography>
                    </ToggleButton>
                    <ToggleButton name="2"
                      selected={isSelected(i, "TWO")}
                      value="2" sx={
                        isRightToLeft ? getSignStyle(round, i, "TWO") : { ...getSignStyle(round, i, "TWO"), ...lastButton } as SxProps<Theme>}>
                      <Typography sx={signText}>2</Typography>
                    </ToggleButton>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          )
        }

        )}
      </Grid>
    </Grid>
  );
}
export default SyndicateCoupon;