import { BackIcon, H2, H3 } from '../../reusable-components';
import { useTranslation } from "react-i18next";
import { getRoundName } from '../../common/RoundHelper';
import { Button, Grid, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { usePlayer } from '../../player/playerContext';
import { IsLeo } from '../../theme/themehelper';
import { Round } from '../../models/round';

type PrizeGroup = {
    correct: number,
    percent: number
};

type PrizeGroupsType = {
    [key: string]: PrizeGroup[]; // String keys with PrizeGroup as values
};

const PrizeGroups: PrizeGroupsType = {

    "Maxtipset": [
        { correct: 13, percent: 60 },
        { correct: 12, percent: 20 },
        { correct: 11, percent: 20 },
    ],

    "Mixtipset": [
        { correct: 12, percent: 60 },
        { correct: 11, percent: 20 },
        { correct: 10, percent: 20 },
    ],

    "Flextipset10": [
        { correct: 10, percent: 100 }
    ],

    "Flextipset8": [
        { correct: 8, percent: 100 }
    ],

    "Flextipset6": [
        { correct: 6, percent: 100 }
    ],

    "MaxtipsetEUR": [
        { correct: 13, percent: 60 },
        { correct: 12, percent: 20 },
        { correct: 11, percent: 20 },
    ],

    "MixtipsetEUR": [
        { correct: 12, percent: 60 },
        { correct: 11, percent: 20 },
        { correct: 10, percent: 20 },
    ],

    "Flextipset10EUR": [
        { correct: 10, percent: 100 }
    ],

    "Flextipset8EUR": [
        { correct: 8, percent: 100 }
    ],

    "Flextipset6EUR": [
        { correct: 6, percent: 100 }
    ],

    "Winter8": [
        { correct: 8, percent: 100 }
    ],    

    "Hockey7": [
        { correct: 7, percent: 100 }
    ],
};


export function RoundInformation({ round, setShowRoundInfo }: { round: Round, setShowRoundInfo: (show: boolean) => void }) {

    const { t } = useTranslation();
    let playerResp: any = usePlayer();
    let apiBetting = playerResp && playerResp[1].allowCouponFileUpload;

    let roundName = getRoundName(round, t);
    const isSmallScreen = useMediaQuery('(max-width: 950px)');

    let borderWidth = "1px";

    const button: SxProps<Theme> = {
        padding: '6px 15px',
        gap: '4px',
        color: 'var(--button-text-color)',
        textTransform: 'var(--text-transform-button)',
        fontWeight: 'var(--font-weight-button)',
        fontSize: '12px',
        lineHeight: '18px',
        border: borderWidth + ' solid #DADADA',
        borderRadius: '5px',
        backgroundColor: "var(--button-color)",
        "&:hover": {
            backgroundColor: "var(--button-hover-color)",
            border: borderWidth + ' solid #DADADA',
        }
    }

    if (IsLeo(window.theme)) {
        button.borderRadius = "var(--button-border-radius)";
        button.padding = "12px 30px";
        button.fontFamily = "var(--font-button)";
        button.fontSize = "14px";
    }

    const prizes = PrizeGroups[round.name];

    const styleSubheader: SxProps<Theme> = { width: isSmallScreen ? "90%" : "400px" };

    const styleOdd: SxProps<Theme> = { background: 'var(--background_2)', justifyContent: "space-between", padding: "5px" }
    const styleEven: SxProps<Theme> = { color: 'var(--text-black-dark-background)', justifyContent: "space-between", padding: "5px" }

    let priceGroups = prizes.sort((a, b) => b.correct - a.correct);


    return (
        <Grid container flexDirection="column" alignItems="center" sx={{ marginBottom: "40px" }}>

            <Grid container alignItems="center" sx={{ width: "90%", margin: "20px" }} onClick={() => setShowRoundInfo(false)}>
                <Grid sx={{ paddingTop: "5px", margin: "0px 10px 0px 0px" }}>{BackIcon("var(--text-black-dark-background)")}</Grid>
                <Typography sx={{ fontWeight: "bold" }}>{t('go_back')}</Typography>
            </Grid>


            <Grid container justifyContent="center" sx={{ width: "100%" }}>
                <H2>{roundName}</H2>
            </Grid>

            <Grid sx={styleSubheader}>
                <H3>{t('id')}: {round.id}</H3>
            </Grid>

            <Grid sx={styleSubheader}>
                <H3>{t('prize_groups')}</H3>
            </Grid>

            <Grid container direction="column" className="flex-list" sx={{ marginBottom: "20px", width: isSmallScreen ? "90%" : "400px" }}>
                <Grid container justifyContent="space-between" alignItems="center" sx={{ ...styleOdd, ...{ fontWeight: "bold" } }}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>{t('correct_rows')}</Typography>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>{t('share_of_prizepool')}</Typography>
                </Grid>
                {priceGroups.map((price, index) => {
                    const styleName = index % 2 > 0 ? styleOdd : styleEven;

                    return <Grid justifyContent="space-between" alignItems="center" container sx={styleName} key={`prize-${index}`}>
                        <Typography sx={{ fontSize: "14px" }}>{price.correct}</Typography>
                        <Typography sx={{ fontSize: "14px" }}>{price.percent}%</Typography>
                    </Grid>

                }
                )}
            </Grid>

            {apiBetting &&
                <>
                    <Grid sx={styleSubheader}>
                        <H3>{t('api:info')}</H3>
                    </Grid>
                    <Grid sx={{ marginBottom: "20px" }}>
                        <Link to="/apibetting">
                            <Button variant="outlined" sx={button}>
                                {t('api_betting')}
                            </Button>
                        </Link>
                    </Grid>
                </>
            }

        </Grid>
    );
}