import { H2 } from "../reusable-components";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Grid, Typography, useMediaQuery } from "@mui/material";
import SyndicatePlate from "./syndicateplate";
import { SyndicatePurchase } from "../models/syndicate";
import { ShallowRound } from "../rounds/types";
import SyndicateAvatar from "./syndicateavatar";

const SyndicateList = ({ syndicates, shallowRounds, agentPage, theme }:
  { syndicates: SyndicatePurchase[], shallowRounds: ShallowRound[], agentPage: boolean, theme: string }) => {

  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width: 620px)');
  let perAgent: any = {};

  if (syndicates !== undefined) {
    syndicates.forEach(element => {

      if (typeof perAgent[element.agentName] !== 'undefined') {
        perAgent[element.agentName].push(element);
      } else {
        var list = [];
        list.push(element);
        perAgent[element.agentName] = list;
      }
    });
  }

  let perAgentList: Array<SyndicatePurchase[]> = [];

  Object.keys(perAgent).forEach(function (key, index) {
    let list: SyndicatePurchase[] = perAgent[key];
    list = list.sort((a, b) => {

      let roundA = shallowRounds.find(r => r.id === a.roundId.toString());
      let roundB = shallowRounds.find(r => r.id === b.roundId.toString());

      if (roundA === undefined || roundB === undefined)
        return 0;

      if (roundA.openTo > roundB.openTo)
        return 1;

      return -1;
    }
    )

    list = list.sort((a, b) => {
      const diff1 = a.targetNShares - a.nShares;
      const diff2 = b.targetNShares - b.nShares;

      // Objects where a - b = 0 should come last
      if (diff1 === 0 && diff2 !== 0) return 1;
      if (diff2 === 0 && diff1 !== 0) return -1;
      return 0; // Keep relative order for the rest
    })


    perAgentList.push(list);
  });

  return (

    <Grid container direction="column" sx={{
      background: 'var(--background_1)',
      padding: isSmallScreen ? "10px" : "40px",
      alignItems: "center"
    }}>

      {perAgentList && perAgentList.map((agent, i) => {

        let realSyndicates = 0;
        let agentName = agent[0].agentName;

        let syndicateList = agent.map((syndicate, j) => {

          let round = shallowRounds.filter(r => r.id === syndicate.roundId.toString());

          if (j > 2 && !agentPage) {
            if (round.length === 1 && round[0].operatorTurnover.currency === syndicate.stakePerShare.currency) {
              realSyndicates++;
            }
            return <div key={"empty" + j} />;
          }

          if (round.length === 1 && round[0].operatorTurnover.currency === syndicate.stakePerShare.currency) {
            realSyndicates++;
            return (
              <SyndicatePlate key={'syndicate' + j} syndicate={syndicate} round={round[0]} theme={theme} />
            )

          }
          return <div key={"empty" + j} />;
        });



        if (realSyndicates === 0) {
          return <div key={"none" + i} ></div>;
        }

        return (

          <Grid key={agent[0].agentName + i.toString()} sx={{ padding: isSmallScreen ? "0px" : "20px", marginBottom: "40px", background: isSmallScreen ? 'inherit' : "var(--syndicate-list-background)" }}>
            <Grid sx={{ marginBottom: "20px" }}>
              <Grid item sx={{ marginBottom: "20px" }}>
                <Grid container direction="row" alignItems="center">
                  <SyndicateAvatar agentName={agentName} />
                  <Grid item sx={{ marginLeft: isSmallScreen ? "0px" : "10px", paddingTop: "5px" }}>
                    <Grid container direction="column" justifyContent="center">
                      <H2 key='title' sx={{ marginBottom: '10px' }} >{agentName}</H2>
                      <Typography sx={{ color: 'var(--text-black)' }}>{realSyndicates} {t('active_syndicates')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!agentPage && <Link to={'/syndicatesforagent/' + agent[0].agentId}><span>{t('to_agent')}</span></Link>}
              {agentPage && <Link to='/playagent'><span>{t('go_back')}</span></Link>}
            </Grid>
            <Grid container direction="row" justifyContent="center" sx={{ maxWidth: "900px", flexWrap: "wrap", marginBottom: "10px" }}>
              {syndicateList}
            </Grid>
          </Grid>
        )
      }
      )}

    </Grid >
  );
}

export default SyndicateList;