import { Grid, Typography } from "@mui/material";
import { getRoundName } from "../../../common/RoundHelper";
import pridePick13 from '../../../images/PridePick13.png';
import pridePick12 from '../../../images/PridePick12.png';
import pridePick8 from '../../../images/PridePick8.png';
import pridePick13Logo from '../../../images/opeimujackpot13.png';
import pridePick12Logo from '../../../images/opeimujackpot12.png';
import pridePick8Logo from '../../../images/opeimujackpot8.png';

import { formatStringCurrency } from "../../../common/CurrencyFormatter";
import { useTranslation } from "react-i18next";
import { themeC } from "../../../theme/themehelper";

function ActiveCouponHeader({ round, winAmount, nCorrectRows, couponShareId }) {

    const { t } = useTranslation();
    const winAmountStr = formatStringCurrency(winAmount.amount, winAmount.currency, t);

    let roundName = getRoundName(round, t);

    let roundImage = pridePick8;
    let roundLogo = pridePick8Logo;
    let backgroundPosition = "-20px";
    let backgroundInfo = "#FFEE90";

    let backgroundImageWidth = "145px";
    let backgroundImageHeight = "71px";

    if (roundName.indexOf("13") > 0) {
        roundImage = pridePick13;
        roundLogo = pridePick13Logo;
    }

    if (roundName.indexOf("12") > 0) {
        roundImage = pridePick12;
        roundLogo = pridePick12Logo;
    }

    if (window.theme === themeC.Gold) {
        backgroundImageWidth = "220px";
        backgroundImageHeight = "71px";
    }

    roundImage = `url(${roundImage})`;

    const smallText = {
        fontSize: "12px",
        color: "#000000",
        lineHeight: "18px",
        fontWeight: "400",
        fontFamily: "var(--font-body)",
        marginRight: "10px"
    };

    const boldText = {
        fontSize: "16px",
        color: "#000000",
        lineHeight: "24px",
        fontWeight: "700",
        fontFamily: "var(--font-body)",
    };

    return (
        <Grid container direction='column' alignItems='center'>
            <Grid item sx={{
                padding: '15px', width: '100%',
                backgroundImage: roundImage, 
                backgroundSize: 'cover',
                backgroundPositionY: backgroundPosition
            }}>
                <Grid container direction='column' alignItems='center'                    >
                    <Grid item sx={{
                        width: backgroundImageWidth,
                        height: backgroundImageHeight,
                        backgroundImage: `url(${roundLogo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='column' alignItems='center' sx={{ padding: '10px', background: backgroundInfo, width: '100%' }}>
                <Typography sx={boldText}>{nCorrectRows + ' ' + t('correct_rows_now')}</Typography>
                <Grid container justifyContent='center' alignItems='baseline'>
                    <Typography sx={smallText}>{t('your_winnings_right_now')}</Typography>
                    <Typography sx={boldText}>{winAmountStr}</Typography>
                </Grid>
            </Grid>

        </Grid>

    );
}

export default ActiveCouponHeader;