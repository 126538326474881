import fetchApi from "../backend/fetchApi";
import { usePlayer } from "../player/playerContext";

const useApiBetting = () => {

  const [player] = usePlayer() as any;

  return {
    uploadFile: async (file: File) => {
      const playerUrl = player && player._links.coupons.href;
      if (!playerUrl) return;

      const formData = new FormData();

      formData.append('file', file, "orders.txt");

      const res = await fetchApi(playerUrl, {
        method: 'POST',
        body: formData
      });

      let json = await res.json();

      json.success = res.ok;

      return json;
    },
  }
}

export default useApiBetting;