import { Avatar } from "@mui/material";
import spelkontot from "./avatars/spelkontot.png"
import kongen from "./avatars/kongen.png"
import prinsessen from "./avatars/prinsessen.png"
import tipsklubben from "./avatars/tipsklubben.png"
import anderssvensson from "./avatars/anderssvesson.png"
import succeandelar from "./avatars/succeandelar.png"

function SyndicateAvatar({ agentName }: { agentName: string }) {

    let img: string | undefined = undefined;

    switch (agentName) {
        case "Spelvinnare":
        case "Spelkontot":
            img = spelkontot;
            break;
        case "Kongen":
            img = kongen;
            break;
        case "Prinsessen":        
            img = prinsessen;
            break;
        case "Tipsklubben":
            img = tipsklubben;
            break;
        case "Anders Svensson":
            img = anderssvensson;
            break;
        case "Succeandelar":
            img = succeandelar;
            break;
    }

    if (img !== undefined) {
        return (
            <Avatar alt={agentName} src={img} sx={{ margin: "10px 10px 10px 0px" }} />
        )
    }

    return (
        <Avatar sx={{ margin: "10px 10px 10px 0px", bgcolor: "blue" }} >{agentName[0]}</Avatar>
    )
}

export default SyndicateAvatar