import { EightIcon, H3Box, PoolxLogoBlack, SixIcon, ThirteenIcon, TwelveIcon } from "../reusable-components";
import moment from 'moment';
import { IsVideo, UseSlimCouponHeader, themeC } from "../theme/themehelper";
import { DateTime } from 'luxon';


export const getReducedFiendlyName = (name) => {
  let friendlyName = name.replaceAll('_', '-');
  return friendlyName;
}

export const formatFraction = v => `${(parseFloat(v) * 100).toFixed(0)}%`;


export const getRoundName = (round, t) => {

  if (!round) {
    return "";
  }

  var isGold = window.theme === themeC.Gold;  
  var isBlue2 = window.theme === themeC.Blue2;
  var isKung = IsVideo(window.theme);

  switch (round.name) {
    case 'Maxtipset':
    case 'MaxtipsetEUR':
      if (isGold) {
        return "Opeimu 13";
      }      
      if (isBlue2) {
        return "Major 13";
      }
      if (isKung) {
        return "Super 13";
      }
      return t('max');
    case 'Mixtipset':
    case 'MixtipsetEUR':
      if (isGold) {
        return "Opeimu 12";
      }
      if (isBlue2) {
        return "Minor 12";
      }
      if (isKung) {
        return "Super 12";
      }
      return t('mix');
    case 'Flextipset6':
    case 'Flextipset8':
    case 'Flextipset10':
    case 'Flextipset6EUR':
    case 'Flextipset8EUR':
    case 'Flextipset10EUR':
      if (isGold) {
        switch (round.name) {
          case 'Flextipset6':
          case 'Flextipset6EUR':
            return "Opeimu 6";
          case 'Flextipset8':
          case 'Flextipset8EUR':
            return "Opeimu 8";
          case 'Flextipset10':
          case 'Flextipset10EUR':
            return "Opeimu 10";
          default:
            return "Opeimu";
        }
      }    
      if (isKung) {
        switch (round.name) {
          case 'Flextipset6':
          case 'Flextipset6EUR':
            return "Super 6";
          case 'Flextipset8':
          case 'Flextipset8EUR':
            return "Super 8";
          case 'Flextipset10':
          case 'Flextipset10EUR':
            return "Super 10";
          default:
            return "Super";
        }
      }
      if (isBlue2) {
        switch (round.name) {
          case 'Flextipset6':
          case 'Flextipset6EUR':
            return "Mini 6";
          case 'Flextipset8':
          case 'Flextipset8EUR':
            return "Mini 8";
          case 'Flextipset10':
          case 'Flextipset10EUR':
            return "Mini 10";
          default:
            return "Mini";
        }
      }
      return t('flex');
    default:
      return round.name;
  }

}

export const getBackground = (round) => {

  if (!round) {
    return '#B8CCF1';
  }

  switch (round.name) {
    case 'Maxtipset':
    case 'MaxtipsetEUR':
      return '#B8CCF1';
    case 'Mixtipset':
    case 'MixtipsetEUR':
      return '#FAEEAE';
    case 'Flextipset6':
    case 'Flextipset8':
    case 'Flextipset10':
    case 'Flextipset6EUR':
    case 'Flextipset8EUR':
    case 'Flextipset10EUR':
      return '#E0D8EE';
    default:
      return '#B8CCF1';
  }

}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getRoundStatus(round, startDate, t) {
  let roundStatus = t('round_status_open')

  const isAfterDeadline = startDate < Date.now();

  if (round.status === "FINISHED") {
    roundStatus = t('round_status_finished');
  } else if (round.status === "ACTIVE") {
    roundStatus = t('round_status_open');
    if (isAfterDeadline) {
      roundStatus = t('round_active_after_deadline');
    }
  } else if (round.status === "VOIDED") {
    roundStatus = t('round_status_voided');
  } else if (round.status === "PAUSED") {
    roundStatus = t('round_status_paused');
  } else {
    roundStatus = "N/A";
  }

  return roundStatus;
}

export function getJackpot(currency, detailedRound) {

  const jackpotkey = 'jackpot'.concat(currency);

  let jackpotEntry = detailedRound ? detailedRound.promotionalValues.filter((item) => item.key === jackpotkey) : [];

  let jackpot = null;
  if (jackpotEntry.length === 1) {
    jackpot = jackpotEntry[0].value;
  }
  else {

    jackpotEntry = detailedRound ? detailedRound.promotionalValues.filter((item) => item.key === 'jackpotEUR') : [];
    if (jackpotEntry.length === 1) {

      let jackpotEur = jackpotEntry[0].value;

      switch (currency) {
        case 'SEK': //11,37
        case 'NOK': //11,82
          jackpot = getSekJackpot(jackpotEur);
          break;
        case 'GHS': //17,38
          jackpot = jackpotEur * 15;
          break;
        case 'MAD': //10,74
          jackpot = jackpotEur * 10;
          break;
        case 'AUD':
        case 'CAD':
        case 'NZD':
        case 'SGD':
        case 'AZN':
          jackpot = jackpotEur * 1.5;
          break;
        case 'BRL':
        case 'PLN':
          jackpot = jackpotEur * 5;
          break;
        case 'INR':
          jackpot = jackpotEur * 80;
          break;
        case 'JPY':
          jackpot = jackpotEur * 130;
          break;
        case 'USD':
        case 'USDT':
        case 'CHF':
          jackpot = jackpotEur;
          break;
        case 'TZS':
          jackpot = jackpotEur * 2000;
          break;
        case 'TRY':
          jackpot = jackpotEur * 30;
          break;
        case 'AMD':
          jackpot = jackpotEur * 400;
          break;
        case 'BYN':
          jackpot = jackpotEur * 3;
          break;
        case 'IRR':
          jackpot = jackpotEur * 600000;
          break;
        case 'IRT':
          jackpot = jackpotEur * 60000;
          break;
        case 'RUB':
          jackpot = jackpotEur * 100;
          break;
        case 'CLP':
          jackpot = jackpotEur * 1000;
          break;
        case 'PEN':
          jackpot = jackpotEur * 4;
          break;
        case 'ETB':
          jackpot = 1000000;
          break;
        default:
          break;
      }
    }
  }

  return jackpot;
}

export function getSekJackpot(jackpotEur) {
  if (jackpotEur < 400000) {
    return jackpotEur * 10;
  }
  if (jackpotEur < 700000) {
    return jackpotEur * 10 + 500000;
  }
  if (jackpotEur < 1100000) {
    return jackpotEur * 10 + 1000000;
  }
  if (jackpotEur < 1400000) {
    return jackpotEur * 10 + 1500000;
  }
  if (jackpotEur < 2000000) {
    return jackpotEur * 10 + 2000000;
  }
  return jackpotEur * 10 + 3000000;
}

export function isCurrencyWithWholeNumbers(currency) {
  switch (currency) {
    case 'TZS':
    case 'IRR':
    case 'IRT':
      return true;
    default:
      break;
  }
  return false;
}

export const getDateString = (round, t) => {

  if (!round) {
    return "";
  }

  const start = new Date(round.openTo);
  const now = new Date(Date.now());
  let today = now;
  today.setHours(0, 0, 0, 0);
  let startDate = start;
  startDate.setHours(0, 0, 0, 0);

  let locale = t('locale');

  let dateTime = DateTime.fromJSDate(new Date(round.openTo)).setLocale(locale);
  let formattedTime = dateTime.toFormat('cccc HH:mm')

  if (startDate < today) {
    return moment(round.openTo).locale(locale).format("L");
  }
  else if (startDate.toDateString() !== today.toDateString()) {
    if (locale === "am-ET")
      return formattedTime;
    return capitalizeFirstLetter(moment(round.openTo).locale(locale).format("dddd HH:mm"));
  }
  else {
    let dateStr = t('today');
    dateStr += ' ';
    dateStr += moment(round.openTo).locale(locale).format("HH:mm");
    return dateStr;
  }
}

export function getRoundPlate(round, t) {

  const roundName = getRoundName(round, t)

  if (UseSlimCouponHeader(window.theme)) {

    return (
      <></>
    );

  }

  switch (round.name) {

    case "Maxtipset":
    case "MaxtipsetEUR":
      return (
        <div className='color-plate' style={{ background: '#B8CCF1' }}>
          <H3Box>{roundName}</H3Box>
          <div className='icon-holder'>
            <ThirteenIcon height="60" />
          </div>
        </div>
      );
    case "Mixtipset":
    case "MixtipsetEUR":
      return (
        <div className='color-plate' style={{ background: '#FAEEAE' }}>
          <H3Box>{roundName}</H3Box>
          <div className='icon-holder'>
            <TwelveIcon height="60" />
          </div>
        </div>
      );
    case "Flextipset6":
    case "Flextipset6EUR":
      return (
        <div className='color-plate' style={{ background: '#E0D8EE' }}>
          <H3Box>{roundName}</H3Box>
          <div className='icon-holder'>
            <SixIcon height="60" />
          </div>
        </div>
      );
    case "Flextipset8":
    case "Flextipset8EUR":
      return (
        <div className='color-plate' style={{ background: '#E0D8EE' }}>
          <H3Box>{roundName}</H3Box>
          <div className='icon-holder'>
            <EightIcon height="60" />
          </div>
        </div>
      );
    default:
      return (
        <div className='color-plate' style={{ background: '#FAEEAE' }}>
          <H3Box>{roundName}</H3Box>
          <div className='icon-holder'>
            <PoolxLogoBlack height="60" />
          </div>
        </div>
      );
  }
}

export function getRoundIcon(round) {
  switch (round.name) {

    case "Maxtipset":
    case "MaxtipsetEUR":
      return (
        <div className='color-plate'>
          <div className='icon-holder'>
            <ThirteenIcon height="20" />
          </div>
        </div>
      );
    case "Mixtipset":
    case "MixtipsetEUR":
      return (
        <div className='color-plate'>
          <div className='icon-holder'>
            <TwelveIcon height="20" />
          </div>
        </div>
      );
    case "Flextipset6":
    case "Flextipset6EUR":
      return (
        <div className='color-plate'>
          <div className='icon-holder'>
            <SixIcon height="20" />
          </div>
        </div>
      );
    case "Flextipset8":
    case "Flextipset8EUR":
      return (
        <div className='color-plate'>
          <div className='icon-holder'>
            <EightIcon height="20" />
          </div>
        </div>
      );
    default:
      return (
        <div className='color-plate'>
          <div className='icon-holder'>
            <EightIcon height="20" />
          </div>
        </div>
      );
  }
}