import { Wrapper } from './styles';
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Grid } from '@mui/material';
import { IsVideo, themeC } from '../../../../../theme/themehelper';

const FixtureTimelineEvent = ({ event, isHomeTeam, fixture, isRightToLeft }) => {
    let extraClassNames = '';

    let styleBubble = {
        borderRadius: "10px",
        backgroundColor: "var(--background_2)",
        border: "1px solid var(--grey-out-50-percent)",
        padding: "10px"
    }

    if (isHomeTeam && !isRightToLeft || !isHomeTeam && isRightToLeft) {
        extraClassNames += ' home-team-event'
    }
    if (event.type === 'HALF_TIME' ||
        event.type === 'FULL_TIME' ||
        event.type === 'KICKOFF' ||
        event.type === 'FINISHED_AFTER_EXTRA') {
        styleBubble = { ...styleBubble, ...{ color: "var(--text-black)", backgroundColor: "var(--off-black)" } }
        extraClassNames += ' centered-event'
        if (window.theme === themeC.Medina || window.theme === themeC.MedinaAr) {
            styleBubble.border = "none";
            styleBubble.backgroundColor = "none";
        }
        if (IsVideo(window.theme)) {
            styleBubble.border = "none";
        }
    }

    if (IsVideo(window.theme)) {
        styleBubble.border = "1px solid #2D4785";        
    }

    if (window.theme === themeC.Video || window.theme === themeC.MrVegas) {
        styleBubble.border = "1px solid #484848";        
    }

    return (
        <div className={'event-row' + extraClassNames}>
            {event.minute ? <MinuteIndicatorSpacing minute={event.minute} extraMinute={event.extra_minute} /> : <EventRowSpacing event={event} fixture={fixture} />}
            <div className={'event-row-wrapper' + extraClassNames}>
                <Grid sx={styleBubble}>
                    <EventBubbleContent event={event} fixture={fixture} isRightToLeft={isRightToLeft} />
                </Grid>
            </div>
            <EventRowSpacing />
        </div>
    );
}

function reverseString(str) {
    return str.split('').reverse().join('');
}

const EventBubbleContent = ({ event, fixture, isRightToLeft }) => {
    const { t } = useTranslation();
    const eventToken = getEventToken(event.type);
    const startingAt = new Date(fixture.startingAt);
    let locale = t('locale');
    switch (event.type) {
        case 'HALF_TIME':
            return (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', color: 'var(--background_2)' }}>
                    <p style={{ fontSize: '16px', margin: '4px', fontWeight: 'bold', color: 'var(--text-white)', textAlign: 'center' }}>
                        {t('half_time')}
                    </p>
                </div>
            );
        case 'FULL_TIME':
            return (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', color: 'var(--background_2)' }}>
                    <p style={{ fontSize: '16px', margin: '4px', fontWeight: 'bold', color: 'var(--text-white)', textAlign: 'center' }}>
                        {t(eventToken)}
                    </p>
                </div>
            );
        case 'KICKOFF':
            return (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', color: 'var(--background_2)' }}>
                    <p style={{ fontSize: '16px', margin: '4px', fontWeight: 'bold', color: 'var(--text-white)', textAlign: 'center' }}>
                        {t(eventToken)} <Moment format={'HH:mm'} locale={locale}>{startingAt}</Moment>
                    </p>
                </div>
            );
        case 'FINISHED_AFTER_EXTRA':
            return (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', color: 'var(--background_2)' }}>
                    <p style={{ fontSize: '16px', margin: '4px', fontWeight: 'bold', color: 'var(--text-white)', textAlign: 'center' }}>
                        {t(eventToken)}
                    </p>
                </div>
            );
        case 'SUBSTITUTION':
            return (
                <div style={{ display: 'flex', flexDirection: 'var(--flex-direction)', alignItems: 'center' }}>
                    <div>
                        <span className={'icon-arrows'} style={{ color: 'var(--text-black)', fontSize: '30px', padding: '0 16px' }} />
                    </div>
                    <div>
                        <div>
                            <div style={{ fontWeight: 'bold', color: 'var(--game-summary-title)', fontSize: '16px' }}>{t(eventToken)}</div>
                            <div style={{ color: 'var(--text-black)', fontWeight: 'bold' }}>{event.player_name}</div>
                            <div style={{ color: 'var(--grey-out-50-percent)' }}>{t('out')}: {event.related_player_name}</div>
                        </div>
                    </div>
                </div>
            );
        case 'YELLOW_RED':
        case 'RED_CARD':
            return (
                <div style={{ display: 'flex', flexDirection: 'var(--flex-direction)', alignItems: 'center' }}>
                    <div>
                        <span className={'icon-card-1'} style={{ color: '#d40202', fontSize: '24px', padding: '0 16px' }} />
                    </div>
                    <div>
                        <div style={{ fontWeight: 'bold', color: 'var(--game-summary-title)', fontSize: '16px' }}>{t(eventToken)}</div>
                        <div style={{ color: 'var(--text-black)', fontWeight: 'bold' }}>{event.player_name}</div>
                    </div>
                </div>
            );
        case 'YELLOW_CARD':
            return (
                <div style={{ display: 'flex', flexDirection: 'var(--flex-direction)', alignItems: 'center' }}>
                    <div>
                        <span className={'icon-card-1'} style={{ color: '#ecc434', fontSize: '24px', padding: '0 16px' }} />
                    </div>
                    <div>
                        <div style={{ fontWeight: 'bold', color: 'var(--game-summary-title)', fontSize: '16px' }}>{t(eventToken)}</div>
                        <div style={{ color: 'var(--text-black)', fontWeight: 'bold' }}>{event.player_name}</div>
                    </div>
                </div>
            );
        case 'GOAL':
            return (
                <div style={{ display: 'flex', flexDirection: 'var(--flex-direction)', alignItems: 'center' }}>
                    <div>
                        <span className={'icon-football'} style={{ color: 'var(--text-black)', fontSize: '24px', padding: '0 16px' }} />
                    </div>
                    <div>
                        <div style={{ fontWeight: 'bold', color: 'var(--game-summary-title)', fontSize: '16px' }}>{t(eventToken)} {isRightToLeft ? reverseString(event.result) : event.result}</div>
                        <div style={{ color: 'var(--text-black)', fontWeight: 'bold' }}>{event.player_name}</div>
                    </div>
                </div>
            );
        case 'PENALTY':
            return (
                <div style={{ display: 'flex', flexDirection: 'var(--flex-direction)', alignItems: 'center' }}>
                    <div>
                        <span className={'icon-football'} style={{ color: 'var(--text-black)', fontSize: '24px', padding: '0 16px' }} />
                    </div>
                    <div>
                        <div style={{ fontWeight: 'bold', color: 'var(--game-summary-title)', fontSize: '16px' }}>{t(eventToken)} {isRightToLeft ? reverseString(event.result) : event.result}</div>
                        <div style={{ color: 'var(--text-black)', fontWeight: 'bold' }}>{event.player_name}</div>
                    </div>
                </div>
            );
        case 'MISSED_PENALTY':
            return (
                <div style={{ display: 'flex', flexDirection: 'var(--flex-direction)', alignItems: 'center' }}>
                    <div>
                        <span className={'icon-football'} style={{ color: '#d40202', fontSize: '24px', padding: '0 16px' }} />
                    </div>
                    <div>
                        <div style={{ fontWeight: 'bold', color: 'var(--game-summary-title)', fontSize: '16px' }}>{t(eventToken)}</div>
                        <div style={{ color: 'var(--text-black)', fontWeight: 'bold' }}>{event.player_name}</div>
                    </div>
                </div>
            );
        case 'OWN_GOAL':
            return (
                <div style={{ display: 'flex', flexDirection: 'var(--flex-direction)', alignItems: 'center' }}>
                    <div>
                        <span className={'icon-football'} style={{ color: '#d40202', fontSize: '24px', padding: '0 16px' }} />
                    </div>
                    <div>
                        <div style={{ fontWeight: 'bold', color: 'var(--game-summary-title)', fontSize: '16px' }}>{t(eventToken)} {isRightToLeft ? reverseString(event.result) : event.result}</div>
                        <div style={{ color: 'var(--text-black)', fontWeight: 'bold' }}>{event.player_name}</div>
                    </div>
                </div>
            );
        default:
            return (
                <div>
                    <div style={{ fontWeight: 'bold', color: 'var(--game-summary-title)', fontSize: '16px' }}>{t(eventToken)}</div>
                    <div style={{ color: 'var(--text-black)', fontWeight: 'bold' }}>{event.player_name}</div>
                </div>
            );
    }
}

const EventRowSpacing = () => {
    return (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ height: '16px', width: '1px', backgroundColor: 'var(--grey-out-50-percent)' }} />
        </div>
    );
};

const MinuteIndicatorSpacing = ({ minute, extraMinute }) => {
    const displayExtraMinute = extraMinute !== "";
    return (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ height: '3px', width: '1px', backgroundColor: 'var(--grey-out-50-percent)' }} />
            <div style={{
                height: '24px', width: '56px', borderRadius: '24px', border: '1px solid var(--grey-out-50-percent)',
                backgroundColor: 'var(--background_2)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
            }}>
                <p style={{ margin: '0', padding: '0', fontSize: '11px', fontWeight: 'bold' }}>
                    {minute}' {displayExtraMinute ? <span>{` + ${extraMinute}'`}</span> : ''}
                </p>
            </div>
            <div style={{ height: '8px', width: '1px', backgroundColor: 'var(--grey-out-50-percent)' }} />
        </div>
    );
};

const getEventToken = (eventType) => {
    switch (eventType) {
        case 'GOAL':
            return 'event_goal';
        case 'PENALTY':
            return 'event_penalty';
        case 'MISSED_PENALTY':
            return 'event_missed_penalty';
        case 'OWN_GOAL':
            return 'event_own_goal';
        case 'VAR':
            return 'event_var';
        case 'YELLOW_CARD':
            return 'event_yellowcard';
        case 'YELLOW_RED':
            return 'event_yellowred';
        case 'SUBSTITUTION':
            return 'event_substitution';
        case 'RED_CARD':
            return 'event_redcard';
        case 'PEN_SHOOTOUT_GOAL':
            return 'event_pen_shootout_goal';
        case 'PEN_SHOOTOUT_MISS':
            return 'event_pen_shootout_miss';
        case 'FULL_TIME':
            return 'event_full_time';
        case 'HALF_TIME':
            return 'event_half_time';
        case 'KICKOFF':
            return 'event_kickoff';
        case 'FINISHED_AFTER_EXTRA':
            return 'event_finished_after_extra';
    }
}

const MatchSummary = ({ fixtureDetails, isRightToLeft }) => {
    const { fixture } = fixtureDetails;
    const homeTeamId = fixture.homeTeam.id;

    if (!fixture.fixtureTimelineEvents) {
        return <Wrapper />
    }

    return (
        <Grid item sx={{ minWidth: "550px" }}>
            <Wrapper>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {fixture.fixtureTimelineEvents.filter(event => event.type !== 'UNKNOWN').map((event, i) =>
                        <FixtureTimelineEvent
                            key={`timeline ${i}`}
                            event={event}
                            isHomeTeam={event.team && homeTeamId === event.team.id}
                            fixture={fixture}
                            isRightToLeft={isRightToLeft} />
                    )}
                </div>
            </Wrapper>
        </Grid>
    );
}

export default MatchSummary;